import { template as template_0e9d6efd5b2b46938e7b1d6037815a56 } from "@ember/template-compiler";
const WelcomeHeader = template_0e9d6efd5b2b46938e7b1d6037815a56(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
