import { template as template_2ba6e1481d754f4c92dde0d00e57c4bb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_2ba6e1481d754f4c92dde0d00e57c4bb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
