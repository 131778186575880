import { template as template_0e913c2f869b41da82b64e3fbce5cf26 } from "@ember/template-compiler";
const FKText = template_0e913c2f869b41da82b64e3fbce5cf26(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
