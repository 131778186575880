import { template as template_d3ea0754059f46fc9241e41d22d43a6e } from "@ember/template-compiler";
const FKControlMenuContainer = template_d3ea0754059f46fc9241e41d22d43a6e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
